var lastScrollTop = 0;
var parsleyForms = [];
var formRecaptcha;
var initialWidth = $(window).innerWidth();
var scrollY = $(window).scrollTop();

$(function () {
    /* lazyload
      =================================================== */
    // $("img.lazyload").lazyload();

    /* Validation form
      =================================================== */
    var submits = document.querySelectorAll('button[type=submit]');
    if(submits && submits.length>0){
        submits.forEach(item=>{
            item.onclick = validate;
        });
    }

    /* DropDown list
      =================================================== */
    $(document).on('click', '.js__scrollToTop', function(e){
        e.preventDefault();

        $('body,html').animate({
            scrollTop: 0,
        }, 100);
    });

    /* DropDown list
      =================================================== */
    $(document).on('click', '.js__down-list', function(){
        $(this).addClass('open-this');
        $(this).parents('.nav__item').find('.nav__down-list').addClass('open-this');
    });

    /* Hamburger menu
      =================================================== */
    $(document).on('click', '.js__toggleToggleMenu', function(e){
        e.preventDefault();

        $(this).toggleClass('is-open');
        $('body').toggleClass('c-toggleMenu--is-open');
    });

    if ($(window).innerWidth() < 1200) {
        $('body').addClass('toFixed--body');
    }

    /* MouseDown
      =================================================== */
    $(document).on('contextmenu', '.js__mouseDown', function(e){
        e.preventDefault();
        var modal = $(this).data('modal');
        if(e.which===3){
            $(modal).modal('show');
        }
    });

    /* Init plugin AOS
      =================================================== */
    AOS.init({
        once: true,
        offset: 10,
        // disable: 'mobile'
        disable: window.innerWidth < 768
    });

    /* Init scrollLoad
      =================================================== */
    scrollLoad();

    /* Video controls
      =================================================== */
    $(document).on('click', '.js__video-controls', function(e){
        $(this).addClass('play');
        $(this).find('.c-video__video').get(0).play();
        $(this).find('.c-video__video').prop("controls",true);
    });

    /* Validation Forms
    =================================================== */
    $('.formParsleyValidate').each(function () {
        var form = $(this);
        var idForm = form.prop('id');
        if(!idForm || idForm.length==0){
            idForm = "form-"+Math.random().toString( 16 ).slice( 2, 10 );
            $(this).attr('id', idForm);
        }

        var parsleyFormIndex = null;
        parsleyForms.forEach(function(item, index){
            if(item.element.id===idForm){
                item.destroy();
                parsleyFormIndex=index;
            }
        });

        if(parsleyFormIndex){
            delete parsleyForms[parsleyFormIndex];
        }

        var parsleyForm = form.parsley();
    
        parsleyForms.push(parsleyForm);
    });

    /* Validation
      =================================================== */
    window.Parsley.on('field:validate', function () {
        var input = $(this.$element).parent();
        if(input){
            if(this.isValid()){
                input.removeClass('parsley-error');
            }else{
                input.addClass('parsley-error');
            }
        }
    });

    /* Detect if touch screen
      =================================================== */
    isTouchDevice();

    /* resizeLoad
      =================================================== */
    resizeLoad();

    $(window).on('resize', function(){
        resizeLoad();

        if ($(window).innerWidth() !== initialWidth) {
            initialWidth = $(window).innerWidth();
            isTouchDevice();
        }
    });
    
    /* legalBox
      =================================================== */
    $('[data-scroll="force"]').on('scroll', function(){
        var $this = $(this);
        var enable_id = $this.data('target');

        // Asegurar compatibilidad con Firefox
        var scrollTop = $this.scrollTop();
        var innerHeight = $this.innerHeight();
        var scrollHeight = $this[0].scrollHeight;

        if (Math.ceil(scrollTop + innerHeight) >= scrollHeight) {  
            $this.parents('.o-form').find(enable_id).prop('disabled', false).prop('checked', true);
        }
    });

    /* mailto (global email)
      =================================================== */
    generateEmail();
});

// !Mobile:: if the document.ready event fails.
$(window).on("load", function () {
    /* mailto (global email)
      =================================================== */
    generateEmail();
});

function generateEmail(){
    $('.js__mailTo').each(function () {
        const user = $(this).data("user");
        const domain = $(this).data("domain");
        const email = `${user}@${domain}`;
        const mailto = `mailto:${email}`;

        $(this).html(`<a href="${mailto}" title="email">${email}</a>`);
    });
}

function isTouchDevice() {
    if( ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0) ) {
        $('body').addClass('c-touch-device');
    } else {
        $('body').removeClass('c-touch-device');
    }
}

function scrollLoad() {
    // on load-page
    if($(window).scrollTop() > 50) {
        // $('.theme').addClass('scrollHeader');
        $('.theme').addClass('scroll__down');
    } else {
        // $('.theme').removeClass('scrollHeader');
        $('.theme').removeClass('scroll__down');
    }

    // isOnScreen (on-load)
    isOnScreen_block();

    // on scroll-page (on-scroll)
    $(window).on('scroll', function(event) {
        // isOnScreen
        isOnScreen_block();

        if($(window).scrollTop() > 50) {
            $('.theme').addClass('scroll__down');
            $('.theme').removeClass('scroll__top');
            $('.c-scroll-to-top').addClass('show');
        } else {
            $('.theme').removeClass('scroll__down');
            $('.theme').removeClass('scroll__up');
            $('.c-scroll-to-top').removeClass('show');
        }

        var scrollPosition = $(this).scrollTop();
        if (scrollPosition > lastScrollTop){
            // down scroll code
            $('.theme').removeClass('scroll__up');
        } else {
            // up scroll code
            $('.theme').addClass('scroll__up');

            if($(window).scrollTop() < 50) {
                $('.theme').removeClass('scroll__up');
                $('.theme').addClass('scroll__top');
            }
        }

        lastScrollTop = scrollPosition;
    }); 
}

function isOnScreen_block() {
    var isVisible_block = $('.is-on-scroll__js').isOnScreen(function(deltas){
        return deltas.top >= 60;
    });

    if (isVisible_block) {
        $('.is-on-scroll__js').addClass('on');
    } 
}

function onSubmitTalkToUs(token){
    
    const form = $('#talkToUsForm');
    form.find('.g-recaptcha').val(token);
    form.find('button[type="submit"]').attr("disabled", "disabled");

    sendFormAjax(form, 
        function(){
            grecaptcha.reset();
            form[0].reset();
            form.find('button[type="submit"]').removeAttr("disabled");
            $('#talk_legal').prop('disabled', true);

            $('#modal_formTalkToUs').modal('hide');
            
            setTimeout(function(){
                $('body').addClass('show--loading');

                setTimeout(function(){
                    $('body').removeClass('show--loading');
                    $('#modal_submitFormOK').modal('show');
                }, 2600);
            }, 2);

        },
        function(){
            grecaptcha.reset();
            form.find('button[type="submit"]').removeAttr("disabled");
            $('#talk_legal').prop('disabled', true);

            setTimeout(function(){
                $('body').addClass('show--loading');

                setTimeout(function(){
                    $('body').removeClass('show--loading');
                    $('#modal_submitFormKO').modal('show');
                }, 2600);
            }, 2);
        }
    )
}

function onSubmitContact(token) { 
    
    const form = $('#contactForm');
    form.find('.g-recaptcha').val(token);

    form.find('button[type="submit"]').attr("disabled", "disabled");

    sendFormAjax(form, 
        function(){

            grecaptcha.reset();
            form[0].reset();
            form.find('button[type="submit"]').removeAttr("disabled");
            $('#contact_legal').prop('disabled', true);
            
            setTimeout(function(){
                $('body').addClass('show--loading');

                setTimeout(function(){
                    $('body').removeClass('show--loading');
                    $('#modal_submitFormOK').modal('show');
                }, 2600);
            }, 6);
        },
        function(){
            grecaptcha.reset();
            form.find('button[type="submit"]').removeAttr("disabled");
            $('#contact_legal').prop('disabled', true);

            setTimeout(function(){
                $('body').addClass('show--loading');

                setTimeout(function(){
                    $('body').removeClass('show--loading');
                    $('#modal_submitFormKO').modal('show');
                }, 2600);
            }, 6);
        }
    )
}

function sendFormAjax(form, callbackSuccess, callbackError){
    $.ajax({
        type: form.attr('method'),
        url: form.attr('action'),
        data: form.serialize(),
        cache:false,
        success: function(result){
            if(callbackSuccess){
                callbackSuccess();
            }
        },
        error: function (request, status, error) {
            if(callbackError){
                callbackError();
            }
        }
    })

}

function validate(event) {
    event.preventDefault();  
    if($($(event.srcElement).parents('form')[0]).parsley().validate()){  
        grecaptcha.execute();
    }
}

function resizeLoad(){
    if(window.innerWidth <= 1200) {
        $('.c-header .nav__link.animate__fadeInDown').removeClass('animate__animated');
    } else {
        $('.c-header .nav__link.animate__fadeInDown').addClass('animate__animated')
    }
}

  